import React from "react"
import Layout from 'gatsby-theme-carbon/src/templates/Default';

const frontmatter = {
  label: 'Payment Receipt',
  title: 'Payment Receipt',
  theme: 'dark',
  description: 'Payment Receipt',
};

/**
 * @param {Object} location is destructured from the internal gatsby navigation api
 */
const PaymentReceipt = ({ pageContext, ...rest  }) => {
  // destructures the state passed via the payment form component
  return (
    <Layout pageContext={{ ...pageContext, frontmatter }} {...rest}>
      <>
        <h1>
          Your payment was successful!<br/>
        </h1>
          You will receive an email with the next steps within the next 48 hours.<br/>
          Thank you for being an Wizdough customer. We sincerely appreciate your business and hope you come back soon!
      </>
    </Layout>
  )
}
export default PaymentReceipt
